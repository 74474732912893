import { RankKeyEnum, UserInPing, UserWithoutSensitive } from '@reach/interfaces';
import { ClientUser } from '../types/user';

export interface UserTransformerOptions {
	impersonating?: boolean;
}

const getUrlWithQueryParam = (givenUrl: string | null): string | null => {
	if (!givenUrl) {
		return null;
	}

	try {
		const url = new URL(givenUrl);
		url.searchParams.set('t', `${Date.now()}`);
		return url.href;
	} catch (error) {
		return null;
	}
};

export function userTransformer(
	user: (UserWithoutSensitive | UserInPing) & { leader?: UserWithoutSensitive },
	options?: UserTransformerOptions
): ClientUser {
	const userInPing = user as UserInPing;

	const level = userInPing.level || 0;
	const rank = userInPing.rank || RankKeyEnum.ADVENTURER;
	const impersonating = options?.impersonating || false;
	const credits = userInPing.numCredits || 0;
	const experiencePoints = userInPing.numExperiencePoints || 0;

	const featureFlags = user.leader?.userFeatureFlags || user.userFeatureFlags;

	const thumbnailUrl = getUrlWithQueryParam(user.thumbnailUrl ?? null);
	const imageUrl = getUrlWithQueryParam(user.imageUrl ?? null);

	return {
		_raw: { ...user },
		id: user.privateId,
		username: user.username,
		type: user.type,
		impersonating,
		level,
		rank,
		credits,
		experiencePoints,
		unclaimedAchievementsCount: 0,
		registerMethod: user.registerMethod,
		featureFlags,
		adminLevel: user.adminFeatureFlags,
		thumbnailUrl,
		imageUrl,
		registeredSource: user.registeredSource,
		email: user.email,
		emailVerified: user.emailVerified,
		fullName: user.fullName,
		firstName: user.firstName,
		lastName: user.lastName,
		birthday: user.birthday,
		profession: user.profession,
		interests: user.interests,
		country: user.country,
		gender: user.gender,
		activatedAt: new Date(user.activatedAt)?.valueOf(),
		followingTeamLeader: user.leader?.privateId !== user.privateId,
	};
}
