import { SearchParams } from '../search';
import { Nullable } from '../typescript';
import {
	ChatBotMessageAIAnswerTypeEnum,
	ChatBotMessageAIImpersonationEnum,
} from './chat-bot-message.type';

export interface ChatBotChatInterface {
	id: string;
	name: string;
	query: Required<SearchParams>;
	impersonation: Nullable<ChatBotMessageAIImpersonationEnum>;
	answerType: Nullable<ChatBotMessageAIAnswerTypeEnum>;
	origin: ChatBotChatOriginEnum;
	strategy: ChatBotStrategyEnum;
	useMemory: boolean;
	createdAt: number;
	updatedAt: number;
	lastUsedAt: number;
}

export enum ChatBotChatOriginEnum {
	DEFAULT = 'DEFAULT',
	SEARCH = 'SEARCH',
	NODE = 'NODE',
}

export enum ChatBotStrategyEnum {
	CHAIN = 'CHAIN',
	AGENT = 'AGENT',
	AGENT_3_5 = 'AGENT_3_5',
	SEMANTIC_3_5 = 'SEMANTIC_3_5',
	SEMANTIC_4_0 = 'SEMANTIC_4_0',
}
