import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpHeaderResponse,
	HttpInterceptor,
	HttpProgressEvent,
	HttpRequest,
	HttpResponse,
	HttpSentEvent,
	HttpUserEvent,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApiAdminInterface, ErrorCodes } from '@reach/interfaces';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiNotReachableService } from '~app-client/api-not-reachable/services';
import { isReachError } from '~app-client/api/utils';
import { CLIENT_ENVIRONMENT } from '~app-client/core/tokens';

@Injectable({ providedIn: 'root' })
export class ApiNotReachableInterceptor implements HttpInterceptor {
	private readonly apiNotReachableService = inject(ApiNotReachableService);
	private readonly environment = inject(CLIENT_ENVIRONMENT);

	intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (!req.url.startsWith(this.environment.apiBaseUrl)) {
			return next.handle(req);
		}

		return next.handle(req).pipe(
			tap({
				next: (event) => {
					this.handleEvent(event);
				},
				error: (event) => {
					this.handleEvent(event);
				},
			})
		);
	}

	private handleEvent(
		event:
			| HttpSentEvent
			| HttpHeaderResponse
			| HttpProgressEvent
			| HttpResponse<unknown>
			| HttpUserEvent<unknown>
	): void {
		if (
			event instanceof HttpErrorResponse &&
			isReachError(event.error) &&
			event.error.code === ErrorCodes.MIDDLEWARE_REQUESTS_NOT_ALLOWED
		) {
			this.apiNotReachableService.navigate(
				event.error.meta?.status as unknown as ApiAdminInterface
			);
		}
	}
}
