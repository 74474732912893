export enum PipelineTypeEnum {
	CREATE_FILE_ALBUM_IMAGE = 'CREATE_FILE_ALBUM_IMAGE',
	CREATE_FILE_DOCUMENT = 'CREATE_FILE_DOCUMENT',
	CREATE_FILE_IMAGE = 'CREATE_FILE_IMAGE',

	CREATE_FILE_AUDIO = 'CREATE_FILE_AUDIO',

	CREATE_FILE_VIDEO = 'CREATE_FILE_VIDEO',
	CREATE_FILE_UNKNOWN = 'CREATE_FILE_UNKNOWN',
	CREATE_FILE_ZIP = 'CREATE_FILE_ZIP',

	CREATE_NOTE = 'CREATE_NOTE',
	CREATE_TAG = 'CREATE_TAG',
	CREATE_WORKSPACE = 'CREATE_WORKSPACE',
	CREATE_ALBUM = 'CREATE_ALBUM',
	CREATE_WEBSITE = 'CREATE_WEBSITE',
	REPLACE_FILE_ALBUM_IMAGE = 'REPLACE_FILE_ALBUM_IMAGE',
	REPLACE_FILE_DOCUMENT = 'REPLACE_FILE_DOCUMENT',
	REPLACE_FILE_IMAGE = 'REPLACE_FILE_IMAGE',

	REPLACE_FILE_AUDIO = 'REPLACE_FILE_AUDIO',

	REPLACE_FILE_VIDEO = 'REPLACE_FILE_VIDEO',
	REPLACE_FILE_UNKNOWN = 'REPLACE_FILE_UNKNOWN',
	REPLACE_FILE_ZIP = 'REPLACE_FILE_ZIP',
	UPDATE_NOTE_CONTENT = 'UPDATE_NOTE_CONTENT',
	UPDATE_NODE_THUMBNAIL = 'UPDATE_NODE_THUMBNAIL',
	RELEARN_NODE = 'RELEARN_NODE',
	RERECOMMEND_TAGS = 'RERECOMMEND_TAGS',
	ASK_AI = 'ASK_AI',
	REFERRAL_EMAILS = 'REFERRAL_EMAILS',
	IMPORT_NODES = 'IMPORT_NODES',
	IMPORT_SHARE_NODES = 'IMPORT_SHARE_NODES',
	EXPORT_NODES = 'EXPORT_NODES',
	EXPORT_SHARE_NODES = 'EXPORT_SHARE_NODES',

	//INTEGRATIONS
	BROWSER_PLUGIN = 'BROWSER_PLUGIN',
	CSV = 'CSV',
	PHONE_CONTACTS = 'PHONE_CONTACTS',
	POCKET = 'POCKET',
	EVERNOTE = 'EVERNOTE',
	ZAPIER_CREATE_NOTE = 'ZAPIER_CREATE_NOTE',
	ZAPIER_CREATE_TAG = 'ZAPIER_CREATE_TAG',
	ZAPIER_CREATE_WEBSITE = 'ZAPIER_CREATE_WEBSITE',
	ZAPIER_CREATE_WORKSPACE = 'ZAPIER_CREATE_WORKSPACE',
	ZAPIER_CREATE_FILE = 'ZAPIER_CREATE_FILE',
	ZAPIER_CREATE_ALBUM = 'ZAPIER_CREATE_ALBUM',
	MAPS_PINS_LIST = 'MAPS_PINS_LIST',
	GOOGLE_DOCS = 'GOOGLE_DOCS',
	DROPBOX_PAPER = 'DROPBOX_PAPER',
	QUIP = 'QUIP',

	GOOGLE_DRIVE = 'GOOGLE_DRIVE',

	//IMPORTS
	IMPORT_MARKDOWN = 'IMPORT_MARKDOWN',
	IMPORT_DOCX = 'IMPORT_DOCX',
	WEBSITE_IMPORT = 'WEBSITE_IMPORT',
}
