export enum QuotaTypeEnum {
	NODES = 'NODES',
	STORAGE = 'STORAGE',
	CHAT_CONVERSATIONS = 'CHAT_CONVERSATIONS',
	MONTHLY_CHAT_WORDS = 'MONTHLY_CHAT_WORDS',
	MONTHLY_CHAT_CHARS = 'MONTHLY_CHAT_CHARS',
	MONTHLY_CHAT_MESSAGES = 'MONTHLY_CHAT_MESSAGES',
	FILE_UPLOAD_LIMIT_IN_BYTES = 'FILE_UPLOAD_LIMIT_IN_BYTES',
	INTEGRATION_NODES = 'INTEGRATION_NODES',
}

export type StandardQuotaTypeEnum = Exclude<QuotaTypeEnum, typeof QuotaTypeEnum.INTEGRATION_NODES>;
