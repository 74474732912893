import {
	BrowserLanguage,
	DateFormat,
	DefaultWorkspaceSettingsEnum,
	DomainSettingsEnum,
	FontSizeEnum,
	HeaderColor,
	HighMediumLow,
	Language,
	MapThemeEnum,
	MapViewTypeEnum,
	ChatBotMessageAIImpersonationEnum,
	ScrollerOption,
	Theme,
	ThemeColor,
	TimeFormat,
	UserPreferences,
	VeryhighToVerylow,
	VisualizerColorTheme,
	WikimediaLanguageCodeEnum,
	WorkspaceListOrderByEnum,
} from '@reach/interfaces';
import { ObjectValidator, ValidatorFn } from '~app-client/core/types';
import { isBoolean, isInteger, ReachStorage } from '~app-client/core/utils';

function isPartOfEnum<T>(validValues: Record<string, T>) {
	return (item: unknown): item is T => {
		return Object.values(validValues).includes(item as T);
	};
}

function isPartOfEnumOrNull<T>(validValues: Record<string, T>) {
	return (item: unknown): item is T | null => {
		return item === null || Object.values(validValues).includes(item as T);
	};
}

function isStringOrNull(item: unknown): item is string | null {
	return item === null || typeof item === typeof 'str';
}

export class SettingsUtils {
	public static get defaultSettings(): UserPreferences {
		return {
			general: {
				theme: Theme.LIGHT,
				themeColor: ThemeColor.BLUE,
				sidebar: HighMediumLow.MEDIUM,
				sidebarOpened: true,
				scroller: ScrollerOption.AUTOMATIC,
				language: SettingsUtils.languageFromBrowser,
				dateFormat: DateFormat.DDMMYYYY,
				timeFormat: TimeFormat.H24,
				occasionalSurveys: true,
				bugReporter: true,
				showTimePrefix: false,
				weekDayLongFormat: false,
				usageAnalysis: true,
				emailSubscriptions: SettingsUtils.subscriptionDefaultValues,
				pushSubscriptions: SettingsUtils.subscriptionDefaultValues,
				headerColor: HeaderColor.BY_RANK,
				fontSize: FontSizeEnum.MEDIUM,
			},
			map: {
				mapTheme: MapThemeEnum.DARK,
				mapViewType: MapViewTypeEnum.MAP,
				showPlaces: true,
				showRoads: true,
				showTransportation: true,
			},
			visualizer: {
				colorTheme: VisualizerColorTheme.DEFAULT,
				in3D: true,
				resolution: VeryhighToVerylow.MEDIUM,
				smoothEdges: true,
				showAnimation: true,
				loadingGraphTime: 10,
				relationshipStrength: VeryhighToVerylow.MEDIUM,
				trackPath: true,
				nodeHover: true,
				glare: false,
			},
			node: {
				wikimediaLanguage: WikimediaLanguageCodeEnum.EN_ENGLISH,
				domainCreation: DomainSettingsEnum.ONLY_TOP,
				defaultWorkspace: DefaultWorkspaceSettingsEnum.LAST_USED,
				defaultWorkspaceId: null,
				workspaceListOrderBy: WorkspaceListOrderByEnum.CREATED_AT,
			},
		};
	}

	public static get initialSettings(): UserPreferences {
		try {
			const stored = SettingsUtils.storedValues;
			return SettingsUtils.mergeWithUnknown(SettingsUtils.defaultSettings, stored);
		} catch (error) {
			console.error(error);
		}

		return SettingsUtils.defaultSettings;
	}

	public static mergeWithUnknown(
		validPreferences: UserPreferences,
		givenPreferences: unknown | undefined
	): UserPreferences {
		return SettingsUtils.mergeUnknownItem(
			validPreferences,
			givenPreferences,
			SettingsUtils.settingsValidators
		);
	}

	public static storeSettings(settings: UserPreferences): void {
		try {
			ReachStorage.setItem(SettingsUtils.storageKey, settings);
		} catch (error) {}
	}

	private static readonly storageKey = '__settingsData';

	private static get storedValues(): UserPreferences | null {
		return ReachStorage.getItem<UserPreferences>(SettingsUtils.storageKey);
	}

	private static mergeUnknownItem<T>(
		valid: T,
		given: unknown | undefined,
		validator: ObjectValidator<T>
	): T {
		const isObj = (item: unknown): item is object => {
			return item != null && typeof item === typeof {} && !Array.isArray(item);
		};

		const validIsObj = isObj(valid);

		if (validIsObj && isObj(validator)) {
			// recursive
			given = given as T;
			return Object.keys(valid).reduce((acc, _key) => {
				const key = _key as keyof T & keyof typeof given & keyof typeof validator;
				acc[key] = SettingsUtils.mergeUnknownItem(
					valid[key],
					(given as T)?.[key],
					validator[key]
				);
				return acc;
			}, {} as T);
		}

		const givenIsValid = (validator as ValidatorFn<T>)?.(given) || false;

		return givenIsValid ? (given as T) : valid;
	}

	private static get languageFromBrowser(): Language {
		const browserLangs: readonly string[] = navigator.languages || [];
		for (const rawLang of browserLangs) {
			const lang = `${rawLang}`.toUpperCase() as Exclude<keyof BrowserLanguage, keyof string>;
			const parsedBrowserLang = BrowserLanguage[lang] as Exclude<
				keyof Language,
				keyof string
			>;
			const parsedLang = Language[parsedBrowserLang];
			if (parsedLang) {
				return parsedLang;
			}
		}
		return Language.EN_GB;
	}

	private static subscriptionDefaultValues = {
		featuresAndAnnouncements: false,
		tipsAndRecommendations: false,
		community: false,
		researchAndFeedback: false,
		newsletters: false,
		eventsAndMilestones: false,
	};

	private static settingsValidators: ObjectValidator<UserPreferences> = {
		general: {
			theme: isPartOfEnum(Theme),
			themeColor: isPartOfEnum(ThemeColor),
			sidebar: isPartOfEnum(HighMediumLow),
			sidebarOpened: isBoolean,
			scroller: isPartOfEnum(ScrollerOption),
			language: isPartOfEnum(Language),
			dateFormat: isPartOfEnum(DateFormat),
			timeFormat: isPartOfEnum(TimeFormat),
			occasionalSurveys: isBoolean,
			bugReporter: isBoolean,
			showTimePrefix: isBoolean,
			weekDayLongFormat: isBoolean,
			usageAnalysis: isBoolean,
			emailSubscriptions: {
				featuresAndAnnouncements: isBoolean,
				tipsAndRecommendations: isBoolean,
				community: isBoolean,
				researchAndFeedback: isBoolean,
				newsletters: isBoolean,
				eventsAndMilestones: isBoolean,
			},
			pushSubscriptions: {
				featuresAndAnnouncements: isBoolean,
				tipsAndRecommendations: isBoolean,
				community: isBoolean,
				researchAndFeedback: isBoolean,
				newsletters: isBoolean,
				eventsAndMilestones: isBoolean,
			},
			headerColor: isPartOfEnum(HeaderColor),
			fontSize: isPartOfEnum(FontSizeEnum),
		},
		visualizer: {
			colorTheme: isPartOfEnum(VisualizerColorTheme),
			in3D: isBoolean,
			resolution: isPartOfEnum(VeryhighToVerylow),
			smoothEdges: isBoolean,
			showAnimation: isBoolean,
			loadingGraphTime: isInteger,
			relationshipStrength: isPartOfEnum(VeryhighToVerylow),
			trackPath: isBoolean,
			nodeHover: isBoolean,
			glare: isBoolean,
		},
		map: {
			mapTheme: isPartOfEnum(MapThemeEnum),
			mapViewType: isPartOfEnum(MapViewTypeEnum),
			showPlaces: isBoolean,
			showRoads: isBoolean,
			showTransportation: isBoolean,
		},
		node: {
			wikimediaLanguage: isPartOfEnum(WikimediaLanguageCodeEnum),
			domainCreation: isPartOfEnum(DomainSettingsEnum),
			defaultWorkspace: isPartOfEnum(DefaultWorkspaceSettingsEnum),
			defaultWorkspaceId: isStringOrNull,
			workspaceListOrderBy: isPartOfEnum(WorkspaceListOrderByEnum),
		},
	};
}
