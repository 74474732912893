import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LevelingSummaryInterface } from '@reach/interfaces';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { LevelingApiService } from '~app-client/api/services';
import { SessionService } from './session';

/**
 * Service that handles all the logic regarding Subject Types.
 */

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class LevelingService {
	private readonly levelingSummary$$ = new BehaviorSubject<LevelingSummaryInterface>(null);
	public readonly levelingSummary$ = this.levelingSummary$$.asObservable().pipe(
		distinctUntilChanged((prev, curr) => {
			return JSON.stringify(prev) === JSON.stringify(curr);
		})
	);
	public get levelingSummary(): LevelingSummaryInterface {
		return this.levelingSummary$$.value;
	}

	private readonly loadingCount$$ = new BehaviorSubject<number>(0);
	public readonly loading$ = this.loadingCount$$.asObservable().pipe(map((count) => count > 0));

	constructor(
		private readonly session: SessionService,
		private readonly levelingApiService: LevelingApiService
	) {
		this.session.loggedOut$.pipe(untilDestroyed(this)).subscribe(() => this.clear());
	}

	public async load(force = true): Promise<void> {
		if (force || this.loadingCount$$.value === 0) {
			try {
				this.loadingCount$$.next(this.loadingCount$$.value + 1);

				const levelingSummary = await this.levelingApiService.getLevelingSummary();
				this.levelingSummary$$.next(levelingSummary);
			} catch (error) {
				throw error;
			} finally {
				this.loadingCount$$.next(Math.max(0, this.loadingCount$$.value - 1));
			}
		}
	}

	private clear(): void {
		this.loadingCount$$.next(0);
		this.levelingSummary$$.next(null);
	}
}
