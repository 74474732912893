import { Injectable } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { FormInterface, FormKeyEnum } from '@reach/interfaces';
import { BehaviorSubject } from 'rxjs';
import { UsersApiService } from '~app-client/api/services';

export enum TypeformGroup {
	BY_RANK = 'BY_RANK',
	OTHERS = 'OTHERS',
}

export interface TypeformFrontend {
	id: FormInterface['id'];
	group: TypeformGroup;
	score: number;

	credits: number;

	completed: boolean;

	url: string;

	name: string;
	description: string;
}

const GROUP_BY_FORM_ID: Omit<Record<TypeformGroup, FormInterface['id'][]>, TypeformGroup.OTHERS> = {
	[TypeformGroup.BY_RANK]: [
		// The order decides how it will be shown
		FormKeyEnum.NEW_USER,
		FormKeyEnum.RANK_UP_TO_ADVENTURER,
		FormKeyEnum.RANK_UP_TO_EXPLORER,
		FormKeyEnum.RANK_UP_TO_PIONEER,
		FormKeyEnum.RANK_UP_TO_VISIONARY,
		FormKeyEnum.RANK_UP_TO_MASTER,
		FormKeyEnum.RANK_UP_TO_EXPERT,
	],
};

const INVERSE_GROUP_BY_FORM_ID: Record<FormInterface['id'], TypeformGroup> = Object.keys(
	GROUP_BY_FORM_ID
).reduce((acc, key) => {
	const values: FormInterface['id'][] = GROUP_BY_FORM_ID[key] || [];
	values.forEach((element) => {
		acc[element] = key;
	});
	return acc;
}, {});

const FORM_GROUP_SCOPE_FN: Record<TypeformGroup, (id: FormInterface['id']) => number> = {
	[TypeformGroup.BY_RANK]: byRankScore,
	[TypeformGroup.OTHERS]: () => 0,
};

const GROUP_RANK_ORDER = GROUP_BY_FORM_ID[TypeformGroup.BY_RANK];

function byRankScore(id: FormInterface['id']): number {
	return GROUP_RANK_ORDER.indexOf(id);
}

function formScore(id: FormInterface['id'], group: TypeformGroup): number {
	return FORM_GROUP_SCOPE_FN[group](id);
}

function parseTypeforms(api: FormInterface[]): TypeformFrontend[] {
	const all: TypeformFrontend[] = api
		.filter((form) => {
			return form.isReadyToFill || form.isCompleted;
		})
		.map((form) => {
			const id = form.id;
			const group = INVERSE_GROUP_BY_FORM_ID[id] || TypeformGroup.OTHERS;
			const score = formScore(id, group);
			const credits = form.rewardCreditsAmount;
			const url = form.url;
			const name = form.name;
			const description = form.description;
			const completed = form.isCompleted;

			return {
				id,
				group,
				score,
				credits,
				url,
				name,
				description,
				completed,
			};
		});

	const byGroup: Partial<Record<TypeformGroup, TypeformFrontend[]>> = all.reduce((acc, form) => {
		acc[form.group] = [...(acc[form.group] || []), form];
		return acc;
	}, {});

	const ret: TypeformFrontend[] = [];

	// Get the forms with max score, and add them if they are not completed.
	const groupKeys = Object.keys(byGroup);
	groupKeys.forEach((group) => {
		const forms: TypeformFrontend[] = byGroup[group] || [];
		const maxForms = forms.reduce((acc, form) => {
			const score = acc[0]?.score ?? -1;
			if (form.score > score) {
				return [form];
			} else if (form.score === score) {
				acc.push(form);
			}
			return acc;
		}, [] as TypeformFrontend[]);

		maxForms.forEach((form) => {
			if (!form.completed) {
				ret.push(form);
			}
		});
	});

	return ret;
}

@Injectable()
export class TypeformService {
	private requestId = 0;
	private lastRequestAnsweredId = 0;

	private readonly typeforms$$ = new BehaviorSubject<TypeformFrontend[]>([]);
	public readonly typeforms$ = this.typeforms$$.asObservable();

	constructor(private readonly usersApi: UsersApiService) {}

	public async load(): Promise<void> {
		const currentId = ++this.requestId;
		const response = await this.usersApi.getForms();

		if (this.lastRequestAnsweredId > currentId) {
			return;
		}

		this.lastRequestAnsweredId = currentId;

		const arr = parseTypeforms(response);

		this.typeforms$$.next(arr);
	}

	public async open(form: TypeformFrontend): Promise<void> {
		await Browser.open({ url: form.url });
		this.typeforms$$.next(
			this.typeforms$$.value.filter((_form) => {
				return form.id !== _form.id;
			})
		);
	}
}
