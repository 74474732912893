import { Injectable } from '@angular/core';
import { LevelingSummaryInterface } from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';

@Injectable({ providedIn: 'root' })
export class LevelingApiService extends BaseApiService {
	protected commonPath = ['users', 'me', 'leveling', 'summary'];

	public getLevelingSummary(): Promise<LevelingSummaryInterface> {
		return this.http.get<LevelingSummaryInterface>(this.url());
	}
}
