import { Stream } from 'stream';

export const streamToBuffer = (stream: Stream): Promise<Buffer> => {
	return new Promise<Buffer>((res, rej) => {
		const chunks: Buffer[] = [];
		stream.on('data', (chunk: Buffer) => {
			chunks.push(chunk);
		});
		stream.on('end', () => res(Buffer.concat(chunks)));
		stream.on('error', rej);
	});
};
