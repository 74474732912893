import { DOCUMENT } from '@angular/common';
import {
	ApplicationRef,
	createComponent,
	EmbeddedViewRef,
	Inject,
	Injectable,
	Injector,
	Renderer2,
	RendererFactory2,
	Type,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RankKeyEnum } from '@reach/interfaces';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { LevelingService, SessionService } from '~app-client/core/services';
import { RankLevelUpModal } from '../_internal/achievements/components/rank-level-up/rank-level-up.component';

/**
 * Service that handles all the logic regarding Tag Types.
 */

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class ProfileClaimableIndicatorService {
	private readonly renderer: Renderer2;

	public get achievementsClaimable(): boolean {
		return this.session.user?.unclaimedAchievementsCount > 0;
	}

	private readonly referralsClaimable$$ = new BehaviorSubject<boolean>(false);
	public readonly referralsClaimable$ = this.referralsClaimable$$
		.asObservable()
		.pipe(distinctUntilChanged());

	public get referralsClaimable(): boolean {
		return this.referralsClaimable$$.value;
	}
	public setReferralsClaimable(value: boolean): void {
		this.referralsClaimable$$.next(value);
	}

	constructor(
		@Inject(DOCUMENT) private document: Document,
		private readonly session: SessionService,
		private readonly injector: Injector,
		private readonly appRef: ApplicationRef,
		rendererFactory: RendererFactory2,
		private readonly levelingService: LevelingService
	) {
		this.renderer = rendererFactory.createRenderer(null, null);
		this.session.loggedOut$.pipe(untilDestroyed(this)).subscribe(() => this.clear());
	}

	public async showRankLevelUp(
		newRank: RankKeyEnum,
		componentToRender: Type<RankLevelUpModal>
	): Promise<void> {
		const componentRef = createComponent(componentToRender, {
			environmentInjector: this.appRef.injector,
		});
		componentRef.instance.rank = newRank;
		componentRef.instance.level = this.levelingService.levelingSummary.level.current;
		this.appRef.attachView(componentRef.hostView);
		const domElement = (componentRef.hostView as EmbeddedViewRef<HTMLElement>)
			.rootNodes[0] as HTMLElement;
		this.renderer.appendChild(this.document.body, domElement);

		componentRef.instance.modalClosed.subscribe(() => {
			this.renderer.removeChild(this.document.body, domElement);
		});
	}

	private clear(): void {
		this.referralsClaimable$$.next(null);
	}
}
