import { QuotaTypeEnum, StandardQuotaStatusResponse } from '../quotas';
import { SearchParams } from '../search';
import { Nullable } from '../typescript';
import {
	ChatBotChatInterface,
	ChatBotChatOriginEnum,
	ChatBotStrategyEnum,
} from './chat-bot-chat.type';
import {
	ChatBotMessageAIAnswerTypeEnum,
	ChatBotMessageAIImpersonationEnum,
	ChatBotUserMessageInterface,
} from './chat-bot-message.type';

export type CreateChatRequest = {
	name: string;
	query: SearchParams;
	impersonation?: Nullable<ChatBotMessageAIImpersonationEnum>;
	answerType?: Nullable<ChatBotMessageAIAnswerTypeEnum>;
	origin?: ChatBotChatOriginEnum;
	strategy?: ChatBotStrategyEnum;
	useMemory?: boolean;
};

export type UpdateChatRequest = Partial<Omit<CreateChatRequest, 'origin'>>;

export type GetChatBotChatsRequest = {
	origins?: ChatBotChatOriginEnum[];
};

export type AskChatBotRequest = {
	question: string;
};

export type GetMessagesQuery = {
	messageIds: string[];
};

export type ChatEmbeddingIds = {
	embeddingIds: string[];
};

export type CreateChatBotChatResponse = {
	chat: ChatBotChatInterface;
	quota: Pick<StandardQuotaStatusResponse, QuotaTypeEnum.CHAT_CONVERSATIONS>;
};

export type UpdateChatBotChatResponse = {
	chat: ChatBotChatInterface;
};

export type AskChatBotResponse = {
	message: ChatBotUserMessageInterface;
	quota: Pick<
		StandardQuotaStatusResponse,
		| QuotaTypeEnum.MONTHLY_CHAT_CHARS
		| QuotaTypeEnum.MONTHLY_CHAT_WORDS
		| QuotaTypeEnum.MONTHLY_CHAT_MESSAGES
	>;
};

export type GetChatBotMessagesResponse = {
	messages: ChatBotUserMessageInterface[];
	quota: Pick<
		StandardQuotaStatusResponse,
		| QuotaTypeEnum.MONTHLY_CHAT_CHARS
		| QuotaTypeEnum.MONTHLY_CHAT_WORDS
		| QuotaTypeEnum.MONTHLY_CHAT_MESSAGES
	>;
};

export type EvaluateChatBotRequest = {
	responseId: string;
	rating: Nullable<number>; // set to null to remove
};
