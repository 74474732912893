import { WorkspaceTemplate } from '@reach/interfaces';

export const templateEmojiMap: Record<WorkspaceTemplate, string> = {
	[WorkspaceTemplate.BLANK_TEMPLATE]: '',
	[WorkspaceTemplate.BUCKET_LIST]: '🪣',
	[WorkspaceTemplate.BUILDING_PROJECT]: '🏗️',
	[WorkspaceTemplate.COMPANY_WIKI]: '📚',
	[WorkspaceTemplate.CRM]: '🤝',
	[WorkspaceTemplate.ENTERTAINMENT]: '🍿',
	[WorkspaceTemplate.FAMILY]: '👨‍👩‍👦',
	[WorkspaceTemplate.SPORTS_AND_FITNESS]: '🏋️‍♀️',
	[WorkspaceTemplate.GAMING]: '🕹️',
	[WorkspaceTemplate.HOME]: '🏠',
	[WorkspaceTemplate.HOUSE_HUNTING]: '🏘️',
	[WorkspaceTemplate.HR]: '🎖️',
	[WorkspaceTemplate.INVESTMENTS]: '💸',
	[WorkspaceTemplate.JOB_APPLICATIONS]: '💼',
	[WorkspaceTemplate.JOURNALISM]: '📰',
	[WorkspaceTemplate.LEGAL]: '⚖️',
	[WorkspaceTemplate.MARKET_STUDY]: '🔎',
	[WorkspaceTemplate.MEDICAL]: '💉',
	[WorkspaceTemplate.PETS]: '🐶',
	[WorkspaceTemplate.PROJECT]: '🚀',
	[WorkspaceTemplate.RECIPES]: '🥕',
	[WorkspaceTemplate.RESTAURANTS]: '🍽️',
	[WorkspaceTemplate.STUDIES]: '🎓',
	[WorkspaceTemplate.THESIS]: '📝',
	[WorkspaceTemplate.TRIPS_AND_TRAVEL]: '🚋',
	[WorkspaceTemplate.WORK]: '🗂️',
};
