import { AdminFeatureFlags, UserType } from '../user';

export interface UserByUsernameOrEmailOrPrivateId {
	query: string;
	types: UserType[];
}

export interface ChangeUsersTypeBody {
	privateIds: string[];
	userType: UserType;
}

export interface DeleteUsersBody {
	privateIds: string[];
}

export interface VerifyEmailsBody {
	privateIds: string[];
}

export interface ChangeExportPermissionBody {
	privateIds: string[];
	canExport: boolean;
}

export interface ChangeAdminFlagAction {
	privateId: string;
	adminFeatureFlag: AdminFeatureFlags;
}

export type ChangeAdminFlagsBody = { changes: ChangeAdminFlagAction[] };

export type CustomerIOItem = { id: number; name: string };

export type GetCampaignsResponse = CustomerIOItem[];
export type GetCampaignEmailsResponse = CustomerIOItem[];
export type UpdateCampaignEmailBody = {
	emailTemplateId: string;
	subject: string;
	preheader: string;
};
