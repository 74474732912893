import {
	AchievementCreditInterface,
	CreditInterface,
	CreditTypeEnum,
	ManualCreditInterface,
	RankCreditInterface,
	UserReferralCreditInterface,
} from '@reach/interfaces';

export function isAchievementCredit(credit: CreditInterface): credit is AchievementCreditInterface {
	return credit.type === CreditTypeEnum.ACHIEVEMENT;
}

export function isManualCredit(credit: CreditInterface): credit is ManualCreditInterface {
	return credit.type === CreditTypeEnum.MANUAL;
}

export function isRankCredit(credit: CreditInterface): credit is RankCreditInterface {
	return credit.type === CreditTypeEnum.RANK;
}

export function isEnigmaCredit(credit: CreditInterface): credit is RankCreditInterface {
	return credit.type === CreditTypeEnum.ENIGMA;
}

export function isFormCredit(credit: CreditInterface): credit is RankCreditInterface {
	return credit.type === CreditTypeEnum.FORM;
}

export function isUserReferralCredit(
	credit: CreditInterface
): credit is UserReferralCreditInterface {
	return credit.type === CreditTypeEnum.USER_REFERRAL;
}

export function isBirthdayPresentCredit(
	credit: CreditInterface
): credit is UserReferralCreditInterface {
	return credit.type === CreditTypeEnum.BIRTHDAY_PRESENT;
}
