import { AchievementInterface } from '@reach/interfaces';
import { stringHashCode } from '../generators';

export function sortAchievementsByProgressionFn(
	a: AchievementInterface,
	b: AchievementInterface
): -1 | 0 | 1 {
	if (a.isReadyToClaim && !b.isReadyToClaim) {
		return -1;
	}
	if (!a.isReadyToClaim && b.isReadyToClaim) {
		return 1;
	}
	if (a.numActionsCompleted > 0 || b.numActionsCompleted > 0) {
		// The || 0 prevents comparing NaNs and Infinity
		const progressRatioForA = a.numActionsCompleted / a.numActionsToUnlock || 0;
		const progressRatioForB = b.numActionsCompleted / b.numActionsToUnlock || 0;
		if (progressRatioForA > progressRatioForB) {
			return -1;
		}
		if (progressRatioForA < progressRatioForB) {
			return 1;
		}
	}
	if (a.badgeId === b.badgeId) {
		// Hacky way to randomize in a consistent way
		const aHash = stringHashCode(a.id);
		const bHash = stringHashCode(b.id);
		if (aHash === bHash) return 0;
		return aHash > bHash ? -1 : 1;
	}
	if (a.position > b.position) {
		return 1;
	}
	if (a.position < b.position) {
		return -1;
	}
	return 0;
}

export function sortAchievementsByBadgeAndPositionFn(
	a: AchievementInterface,
	b: AchievementInterface
): -1 | 0 | 1 {
	if (a.badgeId !== b.badgeId) {
		const aux = a.badgeId.localeCompare(b.badgeId);
		if (aux === 0) return 0;
		return aux > 0 ? 1 : -1;
	}
	if (a.position > b.position) return 1;
	else if (a.position < b.position) return -1;
	return 0;
}
