import { Injectable } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { RankKeyEnum } from '@reach/interfaces';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { DiscourseApiService } from '~app-client/api/services';
import { AnalyticsEvent, AnalyticsService, SessionService } from '~app-client/core/services';

@Injectable()
export class ProfileService {
	public readonly backgroundType$ = this.sessionService.user$.pipe(
		map((user) => user?.rank || RankKeyEnum.ROOKIE)
	);

	private readonly backgroundIndicatorHeight$$ = new BehaviorSubject<number>(0);
	public readonly backgroundIndicatorHeight$ = this.backgroundIndicatorHeight$$
		.asObservable()
		.pipe(distinctUntilChanged());

	private readonly showBackgroundIndicator$$ = new BehaviorSubject<boolean>(true);
	public readonly showBackgroundIndicator$ = this.showBackgroundIndicator$$.asObservable();

	constructor(
		private readonly sessionService: SessionService,
		private readonly analyticsService: AnalyticsService,
		private readonly discourseApi: DiscourseApiService
	) {}

	public setBackgroundVisibility(visibility: 'visible' | 'hidden'): void {
		switch (visibility) {
			case 'visible':
				return this.showBackgroundIndicator$$.next(true);
			case 'hidden':
				return this.showBackgroundIndicator$$.next(false);
		}
	}

	public setBackgroundIndicatorHeight(top: number): void {
		this.backgroundIndicatorHeight$$.next(top);
	}

	public async openForum(): Promise<void> {
		if (Capacitor.isNativePlatform()) {
			await this.openForumNative();
		} else {
			await this.openForumWeb();
		}

		this.analyticsService.addEvent(AnalyticsEvent.DISCOURSE_FEEDBACK_PAGE_OPENED, {});
	}

	private async openForumWeb(): Promise<void> {
		const url = this.discourseApi.loginRedirectEndpoint;
		await Browser.open({ url });
	}

	private async openForumNative(): Promise<void> {
		const response = await this.discourseApi.getLoginLink();
		const url = response.redirectUrl;
		await Browser.open({ url });
	}
}
