export enum Language {
	EN_GB = 'en-gb',
	ES_ES = 'es-es',
}

// LANGUAGE-CODE TO OUR CODE
export enum BrowserLanguage {
	'EN' = 'EN_GB',
	'EN-US' = 'EN_GB',
	'ES-ES' = 'ES_ES',
}
