import { WorkspaceTemplate } from '@reach/interfaces';
import { WorkspaceTemplateCategory } from '~app-client/workspaces/types';

/**
 * Temlates not listed here will be placed at "WorkspaceTemplateCategories.OTHERS"
 */
export const workspaceTemplatesByCategory: Partial<
	Record<WorkspaceTemplateCategory, WorkspaceTemplate[]>
> = {
	[WorkspaceTemplateCategory.EDUCATION]: [WorkspaceTemplate.STUDIES, WorkspaceTemplate.THESIS],
	[WorkspaceTemplateCategory.HOBBIES]: [
		WorkspaceTemplate.BUCKET_LIST,
		WorkspaceTemplate.ENTERTAINMENT,
		WorkspaceTemplate.SPORTS_AND_FITNESS,
		WorkspaceTemplate.GAMING,
		WorkspaceTemplate.PROJECT,
		WorkspaceTemplate.RECIPES,
		WorkspaceTemplate.RESTAURANTS,
		WorkspaceTemplate.TRIPS_AND_TRAVEL,
	],
	[WorkspaceTemplateCategory.HOME]: [
		WorkspaceTemplate.FAMILY,
		WorkspaceTemplate.HOME,
		WorkspaceTemplate.HOUSE_HUNTING,
		WorkspaceTemplate.PETS,
	],
	[WorkspaceTemplateCategory.WORK]: [
		WorkspaceTemplate.BUILDING_PROJECT,
		WorkspaceTemplate.COMPANY_WIKI,
		WorkspaceTemplate.CRM,
		WorkspaceTemplate.HR,
		WorkspaceTemplate.INVESTMENTS,
		WorkspaceTemplate.JOB_APPLICATIONS,
		WorkspaceTemplate.JOURNALISM,
		WorkspaceTemplate.LEGAL,
		WorkspaceTemplate.MARKET_STUDY,
		WorkspaceTemplate.MEDICAL,
		WorkspaceTemplate.WORK,
	],
};

export const workspaceCategoryByTemplates: Partial<
	Record<WorkspaceTemplate, WorkspaceTemplateCategory>
> = Object.keys(workspaceTemplatesByCategory).reduce((acc, category) => {
	return {
		...acc,
		...workspaceTemplatesByCategory[category].reduce((catAcc, tpl) => {
			return {
				...catAcc,
				[tpl]: category,
			};
		}, {}),
	};
}, {});
