import { Nullable } from '../typescript';
import { ChatBotStrategyEnum } from './chat-bot-chat.type';
import { ChatBotToolEnum, ChatBotToolUsageInterface } from './chat-bot-tool.type';

export enum ChatBotMessageTypeEnum {
	AI = 'AI',
	USER = 'USER',
}

export enum ChatBotMessageAIAnswerTypeEnum {
	REASONED = 'REASONED',
}

export enum ChatBotMessageAIImpersonationEnum {
	HAIKU = 'HAIKU',
	YODA = 'YODA',
	EMINEM = 'EMINEM',
	SHAKESPEARE = 'SHAKESPEARE',
	DR_SEUSS = 'DR_SEUSS',
	PIRATE = 'PIRATE',
	GOLLUM = 'GOLLUM',
}

export enum ChatBotMessageStatusEnum {
	SENDING = 'SENDING',
	WRITING = 'WRITING',
	SUCCESS = 'SUCCESS',
	ERROR = 'ERROR',
}

export interface ChatBotBaseMessageInterface {
	id: string;
	type: ChatBotMessageTypeEnum;
	timestamp: number;
	content: string;
	chatId: string;
}

export interface ChatBotAIMessageInterface extends ChatBotBaseMessageInterface {
	type: ChatBotMessageTypeEnum.AI;
	status: ChatBotMessageStatusEnum;
	rawContent: string;
	rating: Nullable<number>; // 0 or 1
	toolsUsed: ChatBotToolUsageInterface<ChatBotToolEnum>[]; // Use generic tool usage interface
}

export interface ChatBotUserMessageInterface extends ChatBotBaseMessageInterface {
	type: ChatBotMessageTypeEnum.USER;
	impersonation?: ChatBotMessageAIImpersonationEnum;
	answerType?: ChatBotMessageAIAnswerTypeEnum;
	strategy: ChatBotStrategyEnum;
	useMemory: boolean;
	response: ChatBotAIMessageInterface;
}
