import { inject, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { CLIENT_ENVIRONMENT } from '~app-client/core/tokens';

interface SentryInterface {
	init: (options: { dsn: string; environment: string }) => void;
	configureScope: (cb: (scope: Sentry.Scope) => void) => void;
	addBreadcrumb: (breadcrumb: Sentry.Breadcrumb) => void;
	captureException: (error: Error, context?: { extra: unknown }) => void;
}

@Injectable({ providedIn: 'root' })
export class SentryService {
	private readonly environment = inject(CLIENT_ENVIRONMENT);
	private readonly sentry = Sentry as unknown as SentryInterface;

	onStart(): void {
		const sentryDSN = this.environment.sentryDSN;
		try {
			if (!!sentryDSN) {
				this.sentry.init({
					dsn: sentryDSN,
					environment: this.environment.environmentName,
				});
			}
		} catch (e) {
			console.error(e);
		}
	}

	public configureScope(cb: (scope: Sentry.Scope) => void): void {
		this.sentry.configureScope((scope) => cb(scope));
	}

	public addBreadcrumb(breadcrumb: Sentry.Breadcrumb): void {
		this.sentry.addBreadcrumb(breadcrumb);
	}

	public captureException(
		description: string | Error,
		context?: { [key: string]: unknown }
	): void {
		try {
			const error = description instanceof Error ? description : new Error(description);

			if (context) {
				this.sentry.captureException(error, { extra: context });
			} else {
				this.sentry.captureException(error);
			}
		} catch (error) {}
	}
}
