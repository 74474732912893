import { DOCUMENT } from '@angular/common';
import {
	ApplicationRef,
	createComponent,
	EmbeddedViewRef,
	inject,
	Injectable,
	Injector,
	Renderer2,
	RendererFactory2,
	Type,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { QuotaStatusItem, QuotaStatusResponse, QuotaTypeEnum } from '@reach/interfaces';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { QuotaApiService } from '~app-client/api/services';
import { FileSizeConfig } from '~app-client/core/utils';
import {
	ChatAiExceededComponent,
	NodesExceededComponent,
	StorageExceededComponent,
} from '~app-client/quota/components';
import { SessionService } from '../session/session.service';
import { ReachQuotaStatus } from './quota.type';
import { parseResponse } from './quota.utils';

/**
 * Service that handles all the logic regarding quota.
 */
@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class QuotaService {
	private readonly quotaApiService = inject(QuotaApiService);
	private readonly sessionService = inject(SessionService);
	private readonly document: Document = inject(DOCUMENT);
	private readonly injector = inject(Injector);
	private readonly rendererFactory = inject(RendererFactory2);
	private readonly appRef = inject(ApplicationRef);
	private readonly renderer: Renderer2;

	public static readonly fileSizeConfig: FileSizeConfig = {
		spacing: true,
		multiplier: 1000,
		precision: 2,
	};

	private readonly quotas$$ = new BehaviorSubject<ReachQuotaStatus | null>(null);
	public readonly quotas$ = this.quotas$$.asObservable();
	public get quota(): QuotaStatusResponse {
		return this.quotas$$.value;
	}

	private readonly loading$$ = new BehaviorSubject<boolean>(false);
	public readonly loading$ = this.loading$$.asObservable();

	private reqId = 0;

	constructor() {
		this.renderer = this.rendererFactory.createRenderer(null, null);
		this.sessionService.user$
			.pipe(
				map((user) => {
					return user?.id;
				}),
				distinctUntilChanged(),
				untilDestroyed(this)
			)
			.subscribe((userId) => {
				if (!userId) {
					this.reset();
				} else {
					this.load();
				}
			});
	}

	public async showNodesQuotaExceeded(): Promise<void> {
		this.loadComponent(NodesExceededComponent);
	}

	public async showStorageQuotaExceeded(): Promise<void> {
		this.loadComponent(StorageExceededComponent);
	}

	public async showChatAiQuotaExceeded(): Promise<void> {
		this.loadComponent(ChatAiExceededComponent);
	}

	private async loadComponent<T>(component: Type<T>): Promise<void> {
		const componentRef = createComponent(component, {
			environmentInjector: this.appRef.injector,
		});
		this.appRef.attachView(componentRef.hostView);
		const domElement = (componentRef.hostView as EmbeddedViewRef<HTMLElement>)
			.rootNodes[0] as HTMLElement;
		this.renderer.appendChild(this.document.body, domElement);
	}

	public getQuota(section: QuotaTypeEnum): QuotaStatusItem | null {
		return this.quota?.[section] ?? null;
	}

	public async load(): Promise<void> {
		const reqId = ++this.reqId;

		try {
			this.loading$$.next(true);
			this.quotaApiService.cleanCache();
			const response = await this.quotaApiService.getUserQuota();

			if (this.ifValid(reqId)) {
				this.quotas$$.next(parseResponse(response));
			}
		} catch (error) {
			console.error(error);
		} finally {
			if (this.ifValid(reqId)) {
				this.loading$$.next(false);
			}
		}
	}

	public reset(): void {
		++this.reqId;
		this.quotas$$.next(null);
		this.loading$$.next(false);
	}

	private ifValid(reqId: number): boolean {
		return reqId === this.reqId;
	}
}
