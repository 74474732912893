import { Injectable } from '@angular/core';
import { AchievementInterface } from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';

@Injectable({ providedIn: 'root' })
export class AchievementsApiService extends BaseApiService {
	protected commonPath = ['achievements'];

	public getAchievement(achievementId: string): Promise<AchievementInterface> {
		return this.http.get<AchievementInterface>(this.url(achievementId));
	}

	public getAchievements(): Promise<AchievementInterface[]> {
		return this.http.get<AchievementInterface[]>(this.url());
	}

	public claimAchievements(achievementId: string): Promise<AchievementInterface> {
		return this.http.post<AchievementInterface>(this.url(achievementId, 'claim'), {});
	}
}
