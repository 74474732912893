import { Injectable } from '@angular/core';
import { RankInterface, RankKeyEnum } from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';

@Injectable({ providedIn: 'root' })
export class RanksApiService extends BaseApiService {
	protected commonPath = ['ranks'];

	public getRanks(): Promise<RankInterface[]> {
		return this.http.get<RankInterface[]>(this.url());
	}

	public claimRank(rankId: RankKeyEnum): Promise<RankInterface> {
		return this.http.post<RankInterface>(this.url(rankId, 'claim'));
	}
}
