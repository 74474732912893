export enum SmartFeatureEnum {
	WIKIMEDIA = 'WIKIMEDIA',
	UNSPLASH = 'UNSPLASH',
	AUTO_EMOJI = 'AUTO_EMOJI',
	OCR_RECOGNITION = 'OCR_RECOGNITION',
	TAGS_RECOGNITION = 'TAGS_RECOGNITION',
	DESCRIPTION_GENERATION = 'DESCRIPTION_GENERATION',
	AUDIO_TRANSCRIPTION = 'AUDIO_TRANSCRIPTION',
	SUMMARY = 'SUMMARY',
}

export enum SmartFeatureOptionEnum {
	ENABLED = 'ENABLED',
	DISABLED = 'DISABLED',
}

export interface SmartFeatureInterface {
	feature: SmartFeatureEnum;
	selectedOption: SmartFeatureOptionEnum;
}

export type UpdateSmartFeatureInterface = Omit<SmartFeatureInterface, 'availableOptions'>;

export const smartFeaturesMap: { [key in SmartFeatureEnum]: SmartFeatureOptionEnum[] } = {
	[SmartFeatureEnum.WIKIMEDIA]: [SmartFeatureOptionEnum.ENABLED, SmartFeatureOptionEnum.DISABLED],
	[SmartFeatureEnum.UNSPLASH]: [SmartFeatureOptionEnum.ENABLED, SmartFeatureOptionEnum.DISABLED],
	[SmartFeatureEnum.AUTO_EMOJI]: [
		SmartFeatureOptionEnum.ENABLED,
		SmartFeatureOptionEnum.DISABLED,
	],
	[SmartFeatureEnum.OCR_RECOGNITION]: [
		SmartFeatureOptionEnum.ENABLED,
		SmartFeatureOptionEnum.DISABLED,
	],
	[SmartFeatureEnum.TAGS_RECOGNITION]: [
		SmartFeatureOptionEnum.ENABLED,
		SmartFeatureOptionEnum.DISABLED,
	],
	[SmartFeatureEnum.DESCRIPTION_GENERATION]: [
		SmartFeatureOptionEnum.ENABLED,
		SmartFeatureOptionEnum.DISABLED,
	],
	[SmartFeatureEnum.AUDIO_TRANSCRIPTION]: [
		SmartFeatureOptionEnum.ENABLED,
		SmartFeatureOptionEnum.DISABLED,
	],
	[SmartFeatureEnum.SUMMARY]: [
		SmartFeatureOptionEnum.ENABLED,
		SmartFeatureOptionEnum.DISABLED,
	],
};
