import { ClientEnvironment, ClientEnvironmentName } from '~app-client/core/types';

export const environment: ClientEnvironment = {
	production: false,
	sentryDSN: 'https://274232054139476297ca200f86c67062@o309455.ingest.sentry.io/5937593',
	environmentName: ClientEnvironmentName.DEVELOPMENT,
	mixpanelToken: 'cffbc8b6e7e847c70ecdbb5cedd8732b',
	frontendUrl: 'https://mobile.experimental.rea.ch',
	apiBaseUrl: 'https://api.experimental.rea.ch/',
	socialSignIn: {
		googleClientId: '106184129344-iphm6q5ab9tvvhq00gvmsb3f16kie2uo.apps.googleusercontent.com',
		appleClientId: 'ch.rea.app.signinweb',
	},

	landingUrl: 'https://myreach.io',

	zapierAppLink:
		'https://zapier.com/developer/public-invite/165875/a14a7936576ebd99c12aee19f43b25e3/',

	imageCdn: 'https://images.weserv.nl/',

	googleMapsApiKey: 'AIzaSyC0Ui4mSH5zJgaabV6_FSzm4IGl57m0goI',

	discourseUrl: 'https://forum.experimental.rea.ch',
	handbookUrl: 'https://handbook.experimental.rea.ch/',
};
