import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserReferralInterface } from '@reach/interfaces';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReferralsApiService } from '~app-client/api/services';
import { SessionService } from '~app-client/core/services';
import { ProfileClaimableIndicatorService } from './profile-claimable-indicator.service';

/**
 * Service that handles all the logic regarding Referals.
 */

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class ReferalsService {
	private readonly referals$$ = new BehaviorSubject<UserReferralInterface[]>([]);
	public readonly referals$ = this.referals$$
		.asObservable()
		.pipe(map((invites) => invites.filter((invite) => !!invite.referredUser)));

	public get referals(): UserReferralInterface[] {
		return this.referals$$.value;
	}

	constructor(
		private readonly session: SessionService,
		private readonly referralsApi: ReferralsApiService,
		private readonly claimableIndicator: ProfileClaimableIndicatorService
	) {
		this.session.loggedOut$.pipe(untilDestroyed(this)).subscribe(() => this.clear());
	}

	public async load(): Promise<void> {
		try {
			const referrals = await this.referralsApi.getReferrals();
			this.referals$$.next(referrals);
		} catch (error) {
			throw error;
		}
	}

	public async claimableReferralsExist(force = true): Promise<boolean> {
		if (force) await this.load();
		if (this.referals$$.value.length === 0) return false;

		for (const referral of this.referals) {
			if (referral.isReadyToClaim) return true;
		}

		return false;
	}

	public async checkForClaimableReferrals(): Promise<void> {
		try {
			const someonesClaimable = await this.claimableReferralsExist();
			if (someonesClaimable) this.claimableIndicator.setReferralsClaimable(true);
		} catch (error) {}
	}

	public referralInfoById(referralId: string): UserReferralInterface {
		return this.referals$$.value.find((element) => element.id === referralId);
	}

	private clear(): void {
		this.referals$$.next([]);
	}

	public async claimReferal(id: string): Promise<void> {
		let indicator;
		try {
			const referal = await this.referralsApi.claimReferral(id);
			this.referals$$.next([
				...this.referals$$.value.map((ref) => (ref.id === referal.id ? referal : ref)),
			]);
			indicator = await this.claimableReferralsExist(false);
		} catch (e) {
			indicator = await this.claimableReferralsExist(true);
		} finally {
			this.claimableIndicator.setReferralsClaimable(indicator);
		}
	}
}
