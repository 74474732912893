/**
 * Error codes that uniquely identify an error within the application.
 * They are grouped in a module/submodule fashion and they are incremental,
 * every module/submodule has up to 1000 error codes and the first error for each
 * module/submodule must have an error code multiple of 1000. An underscore
 * for each error code as a thousand separator.
 */
export enum ErrorCodes {
	E2E_REQUEST_FAILED = 'E2E_REQUEST_FAILED',

	MISC_INTERNAL_SERVER_ERROR = '0_000',
	MISC_NOT_FOUND = '0_001',
	MISC_INVALID_WEBHOOK_SIGNATURE = '0_002',
	MISC_NOT_IMPLEMENTED = '0_003',

	MIDDLEWARE_MISSING_AUTH_TOKEN = '1_000',
	MIDDLEWARE_PASSWORD_ALREADY_CHANGED = '1_001',
	MIDDLEWARE_MINIMUM_CLIENT = '1_002',
	MIDDLEWARE_INVALID_VERSION_FORMAT = '1_003',
	MIDDLEWARE_REQUESTS_NOT_ALLOWED = '1_004',
	MIDDLEWARE_TOO_MANY_REQUESTS = '1_005',
	MIDDLEWARE_INVALID_REQUEST_ORIGIN = '1_006',
	MIDDLEWARE_JSON_PAYLOAD_TOO_LARGE = '1_007',

	COMMON_VALIDATE_USER_NOT_FOUND = '2_000',
	COMMON_VALIDATE_WRONG_USER_TYPE = '2_001',
	COMMON_VALIDATE_USER_ADMIN_PERMISSIONS = '2_002',
	COMMON_VALIDATE_USER_EMAIL_NOT_VERIFIED = '2_003',

	ACHIEVEMENTS_COULD_NOT_CREATE = '3_000',

	LOGGER_LOG_LEVEL_NOT_FOUND = '4_000',

	AUTH_BAD_GOOGLE_PAYLOAD = '5_000',
	AUTH_FIELD_CONFLICT = '5_001',
	AUTH_INCORRECT_PASSWORD = '5_002',
	AUTH_EMAIL_ALREADY_VERIFIED = '5_003',
	AUTH_EMAIL_RECENTLY_SENT = '5_004',
	AUTH_INVALID_ACTION_TYPE = '5_005',
	AUTH_INVALID_REGISTER_METHOD = '5_006',
	AUTH_BAD_APPLE_PAYLOAD = '5_007',
	AUTH_USER_ONLY_SOCIAL = '5_008',
	AUTH_REQUIRES_AUTHENTICATION = '5_009',
	/** @deprecated */
	AUTH_INTEREST_LENGTH_EXCEEDED = '5_010',
	AUTH_AT_LEAST_ONE_BEST_DESCRIBE = '5_011',
	AUTH_REQUIRES_SPECIFIC_USER_FEATURE_FLAG = '5_012',
	AUTH_OAUTH_ERROR = '5_013',
	AUTH_OAUTH_QUERY_USER_DOES_NOT_MATCH_AUTHED_USER = '5_014',
	USERNAME_REQUIRED = '5_015',

	COMMENTS_NOT_FOUND = '6_000',
	COMMENTS_NOT_BELONGING = '6_001',

	CUSTOM_PROPERTIES_INVALID_OPTIONS_ARRAYS = '7_000',
	CUSTOM_PROPERTIES_INVALID_OPTION_SINGLE = '7_001',
	CUSTOM_PROPERTIES_INVALID_PROPERTY_NAME = '7_002',
	CUSTOM_PROPERTIES_INVALID_TYPE = '7_003',
	CUSTOM_PROPERTIES_ASSERTION_FAILED = '7_004',
	CUSTOM_PROPERTIES_NONEXISTENT_TYPE = '7_005',
	CUSTOM_PROPERTIES_NAME_AND_TYPE_CONFLICT = '7_006',
	CUSTOM_PROPERTIES_NO_OPTIONS = '7_007',
	CUSTOM_PROPERTIES_NO_UNIT = '7_0O8',
	CUSTOM_PROPERTIES_NO_TYPE = '7_009',
	CUSTOM_PROPERTIES_NOT_FOUND = '7_010',
	CUSTOM_PROPERTIES_NOT_FOUND_FOR_USER = '7_011',
	CUSTOM_PROPERTY_ALREADY_IN_NODE = '7_012',
	CUSTOM_PROPERTY_NOT_IN_NODE = '7_013',
	CUSTOM_PROPERTY_DOES_NOT_HAVE_VALUE_ACCESS = '7_014',
	CUSTOM_PROPERTY_DOES_NOT_HAVE_PROPERTY_ACCESS = '7_015',
	CUSTOM_PROPERTIES_NONEXISTENT_DATATYPE = '7_016',
	CUSTOM_PROPERTY_ALREADY_EXISTS = '7_017',
	CUSTOM_PROPERTY_IS_NOT_FROM_REACH = '7_018',
	CUSTOM_PROPERTIES_NOT_USER_DEFINED_OPTIONS = '7_019',
	CUSTOM_PROPERTY_PROCCESS_TYPE_NOT_EXPECTED_VALUE = '7_020',

	EDGES_IS_RECURSIVE = '8_000',
	EDGES_WRONG_DIRECTIONALITY = '8_001',

	EMAIL_SUBSCRIPTIONS_ALREADY_EXISTS = '9_000',
	EMAIL_SUBSCRIPTIONS_NOT_EXISTS = '9_001',
	EMAIL_SUBSCRIPTIONS_COULD_NOT_CREATE = '9_002',
	EMAIL_SUBSCRIPTIONS_COULD_NOT_FIND_ALL_EMAILS = '9_002',

	EXPORT_COMPRESSION_FAILED = '10_001',
	/** @deprecated */
	EXPORT_NO_PERMISSIONS = '10_002',
	EXPORT_WRONG_VERSION = '10_003',

	FILES_COULD_NOT_BE_PARSED = '11_000',
	FILES_CREATED_NOT_FOUND = '11_001',
	FILES_NOT_FOUND_FROM_URL = '11_002',
	FILES_COULD_NOT_CREATE_SINGLE = '11_003',
	FILES_COULD_NOT_CREATE_MULTIPLE = '11_004',
	FILES_COULD_NOT_FIND = '11_005',
	FILES_CANNOT_TRANSCRIBE_NON_MEDIA_FILE = '11_006',
	FILES_TRANSCRIPTION_ALREADY_REQUESTED = '11_007',
	FILES_TRANSCRIPTION_IS_NOT_READY = '11_008',
	FILES_UPLOADED_EMPTY_FOLDER = '11_009',
	FILES_MAX_FILE_SIZE_EXCEEDED = '11_010',
	FILES_MAX_BUNDLE_SIZE_EXCEEDED = '11_011',
	FILES_SOURCE_NOT_IMPORTABLE = '11_012',

	/** @deprecated */
	IMPORT_NO_PERMISSIONS = '12_000',
	IMPORT_UNEXPECTED_NODE_TYPE = '12_001',
	IMPORT_INVALID_FORMAT = '12_002',
	IMPORT_ID_TO_PARSE_NOT_FOUND = '12_003',

	MULTER_FILE_TOO_LARGE = '13_000',
	MULTER_FILE_UPLOAD_FAILED = '13_001',
	MULTER_NO_FILE_PROVIDED = '13_002',
	MULTER_WRONG_MIMETYPE = '13_003',
	MULTER_TOO_MANY_ITEMS = '13_004',
	MULTER_FILES_CONTAINS_A_VIRUS = '13_005',

	NODE_DOES_NOT_CONTAIN_PICTURE = '14_000',

	NODES_PROPERTY_NOT_DEFINED = '15_000',
	NODES_TYPE_NOT_SUPPORTED = '15_001',
	NODES_INVALID_CLIENT_SOURCE_OR_CREATION_SOURCE = '15_002',
	WRONG_LENGTH_ID_ARRAY = '15_003',
	NODES_CONTENT_IS_EMPTY = '15_004',
	NODES_CONTENT_IS_LOADING = '15_005',

	SEARCH_TYPE_SHOULD_NOT_HAVE_BEEN_FOUND = '16_000',
	SEARCH_OPERATOR_FORBIDDEN_FOR_TYPE = '16_001',
	SEARCH_OPERATOR_FORBIDDEN_FOR_ATTRIBUTE = '16_002',
	SEARCH_OPERATOR_INVALID = '16_003',
	SEARCH_SEARCH_PARAMS_REQUIRED = '16_004',
	SEARCH_OPERATOR_VALUE_REQUIRED = '16_005',
	PROPERTY_FILTER_VALUE_ASSERTION_FAILED = '16_006',
	NODE_FILTER_NOT_IN_WORKSPACE = '16_007',

	ACCESS_EXISTENCE_NO_ACCESS = '17_000',
	ACCESS_EXISTENCE_NODE_WRONG_WORKSPACE = '17_001',
	ACCESS_EXISTENCE_NODE_WRONG_TYPE = '17_002',
	ACCESS_EXISTENCE_NODE_INSIDE_EXCLUDE = '17_003',
	ACCESS_EXISTENCE_NO_ACCESS_TO_EDGE = '17_004',
	ACCESS_EXISTENCE_USER_NOT_FOUND = '17_005',

	ELASTIC_SEARCH_INVALID_ARGUMENTS = '18_000',
	ELASTIC_SEARCH_UPDATE_FAILED = '18_001',
	ELASTIC_SEARCH_NO_RESULT = '18_002',
	ELASTIC_SEARCH_COULD_NOT_MAP_TYPE_TO_INDEX = '18_003',
	ELASTIC_SEARCH_DOCUMENT_CREATION_FAILED = '18_004',
	ELASTIC_SEARCH_MULTIPLE_DOCUMENT_CREATION_FAILED = '18_005',
	ELASTIC_SEARCH_MULTIPLE_DOCUMENT_UPDATE_FAILED = '18_006',
	ELASTIC_SEARCH_COULD_NOT_PARSE_INDEX_NAME = '18_007',

	FILE_ANALYSIS_INSTANCE_ALREADY_CREATED = '19_000',
	FILE_ANALYSIS_NO_URLS_PROVIDED = '19_001',

	JWT_WRONG_USE_CASE = '20_000',
	JWT_TOKEN_EXPIRED = '20_001',
	JWT_TOKEN_NOT_FOUND = '20_002',
	JWT_ERROR = '20_003',
	JWT_WRONG_ORIGIN = '20_004',

	LOCALS_UNDEFINED_LOCAL_PROPERTY = '21_000',

	GOTENBERG_PDF_CONVERSION_FAILED = '22_000',

	STORAGE_FILE_COULD_NOT_BE_FOUND = '23_000',
	STORAGE_FILE_COULD_NOT_BE_WRITTEN = '23_001',
	STORAGE_FILE_COULD_NOT_BE_COMPRESSED = '23_002',

	SPOTLIGHTS_NOT_FOUND = '24_000',
	SPOTLIGHTS_ALREADY_EXISTS = '24_001',
	SPOTLIGHTS_COULD_NOT_CREATE = '24_002',

	JOBS_SYNC_MIXPANEL_FAILED = '25_000',

	WORKSPACES_NOT_FOUND = '26_000',
	WORKSPACES_COULD_NOT_CREATE = '26_001',
	WORKSPACES_NOT_FOUND_BY_TYPE = '26_002',
	WORKSPACES_ALREADY_EXISTS = '26_003',
	WORKSPACES_COULD_NOT_DELETE_INSIDE_NODES = '26_004',
	WORKSPACES_CANNOT_DELETE_INTEGRATION_WORKSPACE = '26_005',

	UTILS_OBJECT_TO_CLASS_TRANSFORMATION_FAILED = '27_000',
	UTILS_OBJECT_TO_CLASS_TRANSFORMATION_FAILED_WITH_ERRORS = '27_001',
	UTILS_UNEXPECTED_NULL_OR_UNDEFINED = '27_002',
	UTILS_UNIT_CANNOT_BE_CONVERTED = '27_003',
	UTILS_UNEXPECTED_UNREACHABLE = '27_004',
	UTILS_INVALID_JSON_FORMAT = '27_005',

	REDIS_CONNECTION_FAILED = '28_000',

	QUOTAS_NOT_ENOUGH_NODE_SLOTS_AVAILABLE = '29_000',
	QUOTAS_NOT_ENOUGH_STORAGE_SLOTS_AVAILABLE = '29_001',
	QUOTAS_SLOT_INCREMENT_MUST_BE_POSITIVE_INTEGERS = '29_002',
	QUOTAS_SLOT_INCREMENT_HAS_INVALID_VALUE = '29_003',
	QUOTAS_SERVICE_NOT_INJECTED = '29_004',
	QUOTAS_NOT_ENOUGH_CHAT_CONVERSATIONS_SLOTS_AVAILABLE = '29_005',
	QUOTAS_NOT_ENOUGH_CHAT_WORDS_SLOTS_AVAILABLE = '29_006',
	QUOTAS_NOT_ENOUGH_CHAT_CHAR_SLOTS_AVAILABLE = '29_007',
	QUOTAS_NOT_ENOUGH_CHAT_MESSAGES_SLOTS_AVAILABLE = '29_008',
	QUOTAS_NOT_ENOUGH_INTEGRATION_NODE_SLOTS_AVAILABLE = '29_010',

	REACH_TAG_RECOMMENDER_REQUEST_FAILED = '30_000',

	SETTINGS_NO_DATA = '31_000',

	TABLE_VIEWS_NOT_FOUND = '32_000',
	TABLE_VIEWS_COLUMNS_NOT_FOUND = '32_001',

	TUTORIALS_NOT_FOUND = '33_000',
	TUTORIALS_EMPTY_PLAYLIST = '33_001',
	TUTORIALS_VIDEO_WITHOUT_TUTORIAL_TAG = '33_002',
	TUTORIALS_VIDEO_FROM_ANOTHER_CHANNEL = '33_003',
	TUTORIALS_VIDEO_FROM_ANOTHER_PLAYLIST = '33_004',
	TUTORIALS_PLAYLIST_FROM_ANOTHER_CHANNEL = '33_005',
	TUTORIALS_SECONDS_SEEN_GREATER_THAN_VIDEO_DURATION = '33_006',

	USERS_WITH_PRIVATE_ID_NOT_FOUND = '34_000',
	USER_REGISTERED_FROM_INVALID_SOURCE = '34_001',
	USER_NOT_FOUND_BY_QUERY = '34_002',
	INVALID_SUMMARY_INFO_TYPE = '34_003',
	USER_EMAIL_RECENTLY_SENT = '34_004',
	BIRTHDAY_PRESENT_NOT_AVAILABLE = '34_005',

	APPLE_AUTH_VERIFICATION_FAILED = '35_000',
	APPLE_AUTH_CANNOT_FIND_KEY = '35_001',
	APPLE_AUTH_TOKEN_EXPIRED = '35_002',
	APPLE_AUTH_NONCE_DID_NOT_MATCH = '35_003',

	UNSPlASH_TOO_MANY_REQUESTS = '36_000',
	UNSPlASH_FEATURE_NOT_GRANTED = '36_001',

	WEBSITES_COULD_NOT_PERFORM_ANALYSIS = '37_000',
	WEBSITES_COULD_NOT_PARSE_URL = '37_001',
	WEBSITES_NOT_FOUND_BY_URL = '37_002',
	WEBSITES_NOT_FOUND = '37_003',
	WEBSITES_MUST_HAVE_URL = '37_004',
	WEBSITES_MUST_HAVE_URL_OR_TITLE = '37_005',
	WEBSITES_CANNOT_IMPORT_BOOKMARKS_COOLDOWN = '37_006',

	MILVUS_QUERY_FAILED = '38_000',
	MILVUS_SEARCH_FAILED = '38_001',

	WEBSITE_ANALYSIS_MISSING_CONTENT_TYPE = '39_000',
	WEBSITE_ANALYSIS_NOT_A_WEBSITE = '39_001',
	WEBSITE_ANALYSIS_FETCH_TIMEOUT = '39_002',
	WEBSITE_ANALYSIS_FETCH_FAILED = '39_003',
	WEBSITE_ANALYSIS_PARSE_FAILED = '39_004',
	WEBSITE_ANALYSIS_PARSE_TIMEOUT = '39_005',
	WEBSITE_ANALYSIS_READABILITY_TIMEOUT = '39_006',
	WEBSITE_ANALYSIS_DOM_SANITIZE_TIMEOUT = '39_007',

	EMAILS_MAILGUN_API_ERROR = '40_000',
	INSIGHT_NO_METADATA = '40_003',
	INSIGHT_NO_CONFIG = '40_004',
	EMAIL_CANNOT_BE_SENT = '40_005',

	JOBS_COULD_NOT_CHANGE_JOB_RUN_STATUS = '41_000',
	JOBS_COULD_NOT_FIND_LAST_JOB_BY_TYPE = '41_001',
	JOBS_JOB_TYPE_IS_NOT_NODE_JOB = '41_002',

	MIXPANEL_DASHBOARD_LIST_REQUEST_FAILED = '42_000',
	MIXPANEL_BOOKMARK_LIST_REQUEST_FAILED = '42_001',
	MIXPANEL_BOOKMARK_REQUEST_FAILED = '42_002',
	MIXPANEL_BOOKMARK_RATE_LIMIT_REACHED = '42_003',

	NOTES_COULD_NOT_FIND_ATTACHMENT = '43_000',
	NOTES_IMPORT_COULD_NOT_PARSE = '43_001',

	NOTES_INVALID_IMPORT_FORMAT = '43_002',

	WIKIMEDIA_RESPONSE_ERROR = '44_000',

	UPDATE_LOGS_COULD_NOT_CREATE = '46_000',
	UPDATE_LOGS_COULD_NOT_BE_FOUND = '46_001',
	UPDATE_LOGS_IMAGE_INDEX_TO_LONG = '46_002',
	UPDATE_LOGS_INVALID_JSON = '46_003',

	STATISTICS_ID_NOT_FOUND = '47_000',

	NOTE_TEMPLATES_CANNOT_DELETE_FOREIGN_TEMPLATE = '48_000',

	EMAIL_TEMPLATE_NOT_FOUND = '49_000',
	EMAIL_TEMPLATE_ALREADY_EXISTS = '49_001',

	POCKET_MUST_AUTHORIZE_FIRST = '50_000',
	POCKET_API_ERROR = '50_001',
	POCKET_INTEGRATION_NO_BOOKMARKS_TO_BE_IMPORTED = '50_002',

	INTEGRATIONS_NOT_FOUND = '51_000',
	INTEGRATIONS_CANNOT_BE_DELETED = '51_001',
	INTEGRATIONS_UNKNOWN_TYPE = '51_002',
	INTEGRATIONS_CANNOT_EXECUTE_MANUALLY = '51_003',
	INTEGRATIONS_INVALID_HMAC = '51_004',
	INTEGRATIONS_OTHER_USER = '51_005',
	INTEGRATIONS_TYPE_ALREADY_CONTAINS_SYNCED = '51_006',

	REACH_VISION_REQUEST_FAILED = '52_000',

	CANNOT_DELETE_REACH_NODE_SUBTYPE = '53_000',
	NODE_SUBTYPES_MISSING_PROVIDED_CPS = '53_001',
	UNALLOWED_NODE_TYPE_FOR_SUBTYPE_CREATION = '53_002',
	DIFFERENT_NODE_TYPES_FOR_UPDATING_NODE_SUBTYPE = '53_003',
	NODE_SUBTYPES_MISMATCH_NODE_TYPE = '53_004',

	UPTIME_ROBOT_RATE_LIMIT_EXCEEDED = '54_000',
	UPTIME_ROBOT_MONITOR_ID_NOT_FOUND = '54_001',

	ALBUM_FILE_DOES_NOT_BELONG = '55_000',
	ALBUM_FILE_IS_NOT_IMAGE_OR_VIDEO = '55_001',

	ACHIEVEMENTS_ALREADY_CLAIMED = '56_000',
	ACHIEVEMENTS_NOT_READY_TO_CLAIM = '56_001',

	LEVELING_ALREADY_CLAIMED = '57_000',
	LEVELING_NOT_READY_TO_CLAIM = '57_001',

	USER_REFERRAL_ALREADY_CLAIMED = '58_000',
	USER_REFERRAL_NOT_READY_TO_CLAIM = '58_001',
	USER_REFERRAL_NOT_FOUND = '58_002',

	CUSTOMERIO_ERROR = '59_000',

	REMINDER_NOT_FOUND = '60_000',
	REMINDER_WRONG_CP = '60_001',
	REMINDER_NOT_FOUND_CP = '60_002',

	PIPELINES_COULD_NOT_CHANGE_PIPELINE_STATUS = '61_000',
	PIPELINES_COULD_NOT_FIND_LAST_PIPELINE_BY_TYPE = '61_001',

	DISCOURSE_INVALID_PAYLOAD_SIGNATURE = '62_000',

	ENIGMA_DAILY_CHALLENGE_ALREADY_ATTEMPTED = '63_001',
	ENIGMA_TIMEOUT_GRACE_TIME_EXCEEDED = '63_002',
	ENIGMA_CHALLENGE_ALREADY_MARKED_AS_TIMED_OUT = '63_003',
	ENIGMA_CHALLENGE_ALREADY_SUBMITTED = '63_004',
	ENIGMA_ANSWER_TIME_EXCEEDED = '63_005',
	ENIGMA_CHALLENGE_CANNOT_TIMEOUT_SUBMITTED = '63_006',
	ENIGMA_CHALLENGE_CANNOT_GUESS_TIMED_OUT = '63_007',
	ENIGMA_INVALID_SUBCATEGORY_PER_CATEGORY = '63_008',

	REACH_TRANSCRIBER_UPLOAD_FAILED = '64_000',
	REACH_TRANSCRIBER_JOB_ALREADY_FINISHED = '64_001',
	REACH_TRANSCRIBER_INVALID_WEBHOOK_SIGNATURE = '64_002',
	REACH_TRANSCRIBER_TRANSCRIPTION_TIMED_OUT = '64_003',
	REACH_TRANSCRIBER_TRANSCRIPTION_FAILED = '64_004',

	NODES_SUBTYPES_SETTINGS_NODE_SUBTYPE_HAS_MISMATCHING_FEATURES = '65_000',
	NODES_SUBTYPES_SETTINGS_NODE_SUBTYPE_DOES_NOT_HAVE_FEATURE_OPTION = '65_001',
	NODES_SUBTYPES_SETTINGS_NODE_SUBTYPE_HAS_MISMATCHING_EXTRACTED_CONTENTS = '65_002',
	NODES_SUBTYPES_SETTINGS_NODE_SUBTYPE_DOES_NOT_HAVE_EXTRACTED_CONTENT_OPTION = '65_003',
	NODES_SUBTYPES_SETTINGS_CANNOT_UPDATE_NAME_NOR_ICON_OF_GIVEN_NODE_SUBTYPE = '65_004',
	NODES_SUBTYPES_SETTINGS_NODE_SUBTYPE_HAS_MISMATCHING_ADDITIONAL_SETTINGS = '65_005',
	NODES_SUBTYPES_SETTINGS_NODE_SUBTYPE_DOES_NOT_HAVE_ADDITIONAL_SETTING_OPTION = '65_006',

	MAPS_PINS_LIST_REQUEST_FAILED = '66_000',

	TICKET_ALREADY_RESOLVED = '67_000',
	SELF_IMPERSONATION = '67_001',

	TICKET_HAS_ACCESSES = '67_002',

	TYPEFORM_FORM_NOT_FOUND = '68_000',
	TYPEFORM_FORM_ALREADY_SUBMITTED = '68_001',
	TYPEFORM_UNKNOWN_WEBHOOK_EVENT_TYPE = '68_002',
	TYPEFORM_FORM_IS_NOT_READY_TO_FILL = '68_003',

	IMPORT_EVERNOTE_AUTHENTICATION_FAILED = '69_000',
	IMPORT_EVERNOTE_RATE_LIMIT_SURPASSED = '69_001',
	IMPORT_EVERNOTE_ITEM_NOT_FOUND = '69_002',
	IMPORT_EVERNOTE_TOO_MANY_WORKSPACES = '69_003',

	REACH_MIND_PROCESS_REQUEST_FAILED = '70_000',
	REACH_MIND_ASK_REQUEST_FAILED = '70_001',
	REACH_MIND_CLEAR_MEMORY_REQUEST_FAILED = '70_002',
	REACH_MIND_NLP_QUERY_REQUEST_FAILED = '70_003',

	SHARE_NODES_MAX_ACTIVE_SHARES_COUNT = '71_000',
	SHARE_NODES_EXCEEDS_MAX_NODE_COUNT = '71_001',
	SHARE_NODES_EXCEEDS_MAX_STORAGE_SIZE = '71_002',
	SHARE_NODES_EXPIRED = '71_003',
	SHARE_NODES_EXCEEDS_ALREADY_EXTENDED = '71_004',
	SHARE_NODES_USER_CANNOT_IMPORT = '71_005',

	CHAT_BOT_CHAT_NOT_FOUND = '72_000',
	CHAT_BOT_MESSAGES_NOT_FOUND = '72_001',
	CHAT_BOT_NO_UPDATE_DATA_PROVIDED = '72_002',

	GOOGLE_AUTH_ACCESS_TOKEN_EXPIRED = '73_000',
	GOOGLE_AUTH_REFRESH_TOKEN_EXPIRED = '73_001',
	GOOGLE_AUTH_MUST_AUTHORIZE_FIRST = '73_002',
	GOOGLE_DRIVE_RATE_LIMIT_SURPASSED = '73_003',
	GOOGLE_DRIVE_FILE_TOO_BIG_FOR_EXPORT = '73_004',
	GOOGLE_DRIVE_CLIENT_NOT_RETURNING_FILE = '73_005',

	INTEGRATION_EXECUTION_NOT_FOUND = '74_000',
	INTEGRATION_EXECUTION_CANNOT_CREATE_INTEGRATION_EXECUTION_TYPE_COOLDOWN = '74_001',
	INTEGRATION_EXECUTION_CANNOT_CREATE_INTEGRATION_EXECUTION_UNTIL_LAST_FINISHES = '74_002',

	SUMMARY_REQUEST_FAILED = '75_000',

	TEAM_NAME_ALREADY_EXISTS = '76_000',
	TEAM_MEMBER_ALREADY_IN_ANOTHER_TEAM = '76_001',
	TEAM_DOESNT_EXIST = '76_002',
	TEAM_NOT_ENOUGH_MEMBERS = '76_003',
	TEAM_INCLUDES_NON_ALPHA_USERS = '76_004',
}
