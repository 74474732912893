import { NodeSubtypeEnum, nodeSubtypeToNodeType } from '../../node-subtypes';
import { NodeType } from '../../nodes';
import { deprecatedSatisfies } from '../../typescript';
import { NodeSubtypeAdditionalSettingEnum } from './additional-setting.enum';
import { TagCreationFormatOption } from './additional-settings-options.enums';

export const optionsPerAdditionalSetting = deprecatedSatisfies<{
	[setting in NodeSubtypeAdditionalSettingEnum]: string[];
}>()({
	[NodeSubtypeAdditionalSettingEnum.TAG_CREATION_FORMAT]: Object.values(TagCreationFormatOption),
} as const);

export const additionalSettingsPerNodeType = deprecatedSatisfies<{
	[n in NodeType]: NodeSubtypeAdditionalSettingEnum[];
}>()({
	[NodeType.TAG]: [NodeSubtypeAdditionalSettingEnum.TAG_CREATION_FORMAT],
	[NodeType.NOTE]: [],
	[NodeType.ALBUM]: [],
	[NodeType.FILE]: [],
	[NodeType.WORKSPACE]: [],
	[NodeType.WEBSITE]: [],
});

export const defaultAdditionalSettingOptionPerNodeType = deprecatedSatisfies<{
	[nodeType in NodeType]: {
		[setting in (typeof additionalSettingsPerNodeType)[nodeType][number]]: (typeof optionsPerAdditionalSetting)[setting][number];
	};
}>()({
	[NodeType.TAG]: {
		[NodeSubtypeAdditionalSettingEnum.TAG_CREATION_FORMAT]: TagCreationFormatOption.NAME,
	},
	[NodeType.WORKSPACE]: [],
	[NodeType.FILE]: [],
	[NodeType.ALBUM]: [],
	[NodeType.NOTE]: [],
	[NodeType.WEBSITE]: [],
} as const);

export const defaultAdditionalSettingOptionPerNodeSubtype = deprecatedSatisfies<{
	[subtype in NodeSubtypeEnum]: {
		[setting in (typeof additionalSettingsPerNodeType)[(typeof nodeSubtypeToNodeType)[subtype]][number]]: (typeof optionsPerAdditionalSetting)[setting][number];
	};
}>()({
	[NodeSubtypeEnum.TAG_CONTACT]: {
		[NodeSubtypeAdditionalSettingEnum.TAG_CREATION_FORMAT]: TagCreationFormatOption.NAME,
	},
	[NodeSubtypeEnum.TAG_TOPIC]: {
		[NodeSubtypeAdditionalSettingEnum.TAG_CREATION_FORMAT]: TagCreationFormatOption.NAME,
	},
	[NodeSubtypeEnum.TAG_COMPANY]: {
		[NodeSubtypeAdditionalSettingEnum.TAG_CREATION_FORMAT]: TagCreationFormatOption.NAME,
	},
	[NodeSubtypeEnum.TAG_PLACE]: {
		[NodeSubtypeAdditionalSettingEnum.TAG_CREATION_FORMAT]: TagCreationFormatOption.MAP_PICK,
	},
	[NodeSubtypeEnum.ALBUM_BASE]: [],
	[NodeSubtypeEnum.FILE_ALBUM_ITEM]: [],
	[NodeSubtypeEnum.FILE_BASE]: [],
	[NodeSubtypeEnum.FILE_IMAGE]: [],
	[NodeSubtypeEnum.FILE_VIDEO]: [],
	[NodeSubtypeEnum.FILE_AUDIO]: [],
	[NodeSubtypeEnum.NOTE_BASE]: [],
	[NodeSubtypeEnum.WEBSITE_BASE]: [],
	[NodeSubtypeEnum.WEBSITE_DOMAIN]: [],
	[NodeSubtypeEnum.WORKSPACE_BASE]: [],
} as const);
