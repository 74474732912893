import { BadgeInterface } from '@reach/interfaces';

export function sortBadgesByProgressionFn(a: BadgeInterface, b: BadgeInterface): -1 | 0 | 1 {
	if (a.lastAchievementClaimedAt != null && b.lastAchievementClaimedAt == null) {
		return -1;
	}
	if (a.lastAchievementClaimedAt == null && b.lastAchievementClaimedAt != null) {
		return 1;
	}
	if (a.lastAchievementClaimedAt != null && b.lastAchievementClaimedAt != null) {
		return a.lastAchievementClaimedAt.valueOf() > b.lastAchievementClaimedAt.valueOf() ? 1 : -1;
	}
	if (a.level > b.level) return -1;
	else if (a.level < b.level) return 1;

	if (a.position > b.position) return 1;
	else if (a.position < b.position) return -1;
	return 0;
}

export function sortBadgesByPositionFn(a: BadgeInterface, b: BadgeInterface): -1 | 0 | 1 {
	if (a.position > b.position) return 1;
	else if (a.position < b.position) return -1;
	return 0;
}
