import { propertyTypeTextOverrideMap } from './property-type-text-override.map';

export const reversedPropertyTypeTextOverrideMap = Object.keys(propertyTypeTextOverrideMap).reduce(
	(acc, key) => {
		return {
			...acc,
			[propertyTypeTextOverrideMap[key]]: key,
		};
	},
	{}
);
