import { Injectable } from '@angular/core';
import { CreditsSummaryInterface, CreditTransactionInterface } from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';

@Injectable({ providedIn: 'root' })
export class CreditsApiService extends BaseApiService {
	protected commonPath = ['credits'];

	public getCreditsSummary(): Promise<CreditsSummaryInterface> {
		return this.http.get<CreditsSummaryInterface>(this.url('summary'));
	}
	public getCreditsTransactions(): Promise<CreditTransactionInterface[]> {
		return this.http.get<CreditTransactionInterface[]>(this.url('transactions'));
	}
}
