import { Injectable } from '@angular/core';
import { UserReferralInterface } from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';

@Injectable({ providedIn: 'root' })
export class ReferralsApiService extends BaseApiService {
	protected commonPath = [];

	public inviteFriends(emails: string[]): Promise<string[]> {
		return this.http.post<string[]>(this.url('auth', 'refer'), {
			referrals: emails,
		});
	}

	public getReferrals(): Promise<UserReferralInterface[]> {
		return this.http
			.get<{ referredUsers: UserReferralInterface[] }>(this.url('users', 'referrals'))
			.then((response) => response.referredUsers);
	}

	public claimReferral(id: string): Promise<UserReferralInterface> {
		return this.http.post<UserReferralInterface>(this.url('users', 'referrals', id, 'claim'));
	}

	public getRegisteredEmails(emails: string[]): Promise<string[]> {
		return this.http
			.get<{ emails: string[] }>(this.url('users', 'referrals', 'disambiguate-emails'), {
				emails,
			})
			.then((response) => response.emails);
	}
}
