import { Injectable } from '@angular/core';
import { BadgeInterface } from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';

@Injectable({ providedIn: 'root' })
export class BadgesApiService extends BaseApiService {
	protected commonPath = ['badges'];

	public getBadges(): Promise<BadgeInterface[]> {
		return this.http.get<BadgeInterface[]>(this.url());
	}
}
