import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Network } from '@capacitor/network';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { ApiDownService } from '~app-client/api-down/services';
import { genericRetryStrategy } from '~app-client/api/utils';
import { CLIENT_ENVIRONMENT } from '~app-client/core/tokens';

@Injectable({ providedIn: 'root' })
export class ApiDownInterceptor implements HttpInterceptor {
	private readonly apiDownService = inject(ApiDownService);
	private readonly environment = inject(CLIENT_ENVIRONMENT);

	intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (!req.url.startsWith(this.environment.apiBaseUrl)) {
			return next.handle(req);
		}

		return next.handle(req).pipe(
			filter((event) => event instanceof HttpResponse),
			genericRetryStrategy(),
			tap({
				next: () => {},
				error: (event: HttpResponse<unknown>) => {
					this.handleEvent(event);
				},
			})
		);
	}

	private async handleEvent(event: HttpResponse<unknown>): Promise<void> {
		if (event.status === 0 && event.url.startsWith(this.environment.apiBaseUrl)) {
			const network = await Network.getStatus();
			if (network.connected) {
				console.log('API DOWN - request to', event.url, ' FAILED');
				this.apiDownService.navigateToPage();
			}
		}
	}
}
